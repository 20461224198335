body {
    margin: 0;
    padding: 0;    
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 1em;
}

h1 {    
    font-size: 1.5em;
    color: #028cca;
    /* font-weight: bold;*/
    margin: 15px 0px 10px 0px;
    width: 60%;
}

h2 {    
    font-size: 1.3em;
    color: #000;
    margin: 15px 0px 10px 0px;
}

.highlightedText {
    color: #028cca;
}


.smallText {
    color: red;
    margin: 0px 0px 10px 0px;
}

.textH2 {
    font-family: "Roboto";
    font-size: 1em;
    color: #000;
    margin: 15px 0px 10px 0px;
}

#embedContainer {
    height: 800px;
    width: 1330px;
}

.loadingContainer {
    width: 48rem;
}

ul.nav li.active a {
    color: #6d7880 !important;
}

ul.nav li a:hover, ul.nav li a:active {
    color: #fff !important;
}


.badge {
    background-color: #e4dede;
    font-weight: normal;
    color: #000;
    margin: 4px;
    padding: 4px;
}

.labelH1 {
    font-size: 1.5em;
    color: #028cca;
    margin: 15px 0px 10px 0px;
    width: 100%;
}
 

.bookmark-dropdown-toggle {
    font-size: 0.875rem;
    float: right;
    padding: 4px;
}

.bookmark-dropdown-menu {
   /* cursor: pointer;
    position: absolute !important;
    left: -8em;
    top: 3em;
    min-width: 300px;
    max-width: 800px;
    padding: 0px;
    margin: 0px 0px 0px 0px;*/
}

.bookmark-dropdown-item {
    /*padding: 0rem 0.5rem 0rem 0.5rem;*/
    clear: both;
    color: darkgray;
}


.bookmark-dropdown-item-active {
    /*padding: 0rem 0.5rem 0rem 0.5rem;*/
    clear: both;
    color: black;
}
.bookmark-row {
    display: flex;
    flex-direction: row;
    
}

.bookmark-control {
    display: flex;
    text-align: right;
    width:380px;
  
}
.bookmarcCol {
    background-color: green;
}
.bookmark-name-text {
    color: #ffffff;
    background-color: #1d5e7e;
    font-size: 0.875rem;
    padding-left: 10px;
    padding-right: 10px;
}
.bookmark-div {
    display: flex;
}
.emptyBookmarkListText {
    display: flex;
    padding-left: 5px; 
    width: 380px;
}


.reportParameterTrExcluded {
    background-color: #f2f2f2;
    color: darkgrey;
}
.reportParameterTr {
    background-color: white;
    color: #000000;
}


.reportParameterTooltip {
    font-size: 0.9em;
        color: #0075ff;
}


.reportParameterSortingDiv {
    margin: 15px 10px 10px 0px;
    padding: 10px 10px 10px 0px;
}


.bookmark-menu-button {
    margin: 0px 5px 0px 0px;
    padding: 3px 10px 3px 10px;
    float: left;
}

.shared-dashboard-ok {
    text-align: center;
    margin: 20px 20px 20px 20px;
    padding: 10px 10px 10px 10px;
}

.runScheduleModal {
    margin: 5px 5px 5px 5px;
  
}

.navbarDark {
    background-color: #141B22;
    width: 100%;
}